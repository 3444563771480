import React from "react";
import { useNavigate } from "react-router-dom";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import { formatFinance } from "../common/utils";
import useDayjsFormat from "../hooks/useDayjsFormat";

const ShopBlockProject = ({ item }) => {
  const navigate = useNavigate();

  const { getFullDate } = useDayjsFormat();

  const handleClick = () => {
    navigate("/dashboard/shop/" + item.id + "/info");
  };

  let clazz;
  let status;
  let projectClazz;
  let btnColor;
  if (item.status === 1) {
    clazz = "text_sm text-success";
    projectClazz = "project_success";
    status = "Статус Активен";
    btnColor = "btn btn-success";
  } else if (item.status === -1) {
    clazz = "text_sm text-error";
    status = "Статус Заблокирован";
    projectClazz = "project_error";
    btnColor = "btn btn-error";
  } else if (item.status === 0) {
    clazz = "text_sm text-warning";
    status = "Статус Ожидает активации";
    projectClazz = "project_warning";
    btnColor = "btn btn-warning";
  }

  const isBlockedStatus = () => {
    return item.status === -1;
  };

  return (
    <AnimatedBlock>
      <div className={`project ${projectClazz}`}>
        <div className="project__section">
          <div className="project__title">
            <div className="text">{item.name}</div>
            <div className={clazz}>{status}</div>
          </div>

          <div className="project__info">
            <div>
              <div className="text-muted">Текущий баланс</div>
              <div className="text">{formatFinance(item.balance)} ₽</div>
            </div>

            <button
              disabled={isBlockedStatus()}
              className={btnColor}
              onClick={handleClick}
            >
              Детальнее
            </button>
          </div>
        </div>

        <div className="project__devider"></div>

        <div className="project__section">
          <div className="d-flex gap-40">
            <div>
              <div className="text-muted">Последний платеж</div>
              <div className="text">{getFullDate(item.updatedAt)}</div>
            </div>

            <div>
              <div className="text-muted">Сумма платежей</div>
              <div className="text">{formatFinance(item.total_income)} руб</div>
            </div>

            <div>
              <div className="text-muted">Сумма выводов</div>
              <div className="text">{formatFinance(item.total_payout)} руб</div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedBlock>
  );
};

export default ShopBlockProject;
