import React, { useState } from "react";

const ModalWallet = ({ active, setActive, setMethodNameWallet }) => {
  const [selectedWallet, setSelectedWallet] = useState(1);

  // добавить получение по  API
  const selectOptionWallet = [
    { id: 1, name: "Wallet" },
    { id: 2, name: "QIWI" },
    { id: 3, name: "VISA / MASTERCARD" },
    { id: 4, name: "МИР" },
    { id: 5, name: "+7 800 555 35 35" },
    { id: 6, name: "+7 900 666 46 46" },
  ];

  const handleSubmit = () => {
    setActive(false);
  };

  const handleClick = (e) => {
    setActive(false);
  };

  return (
    <div
      className={active ? "modal modal-select shown" : "modal modal-select"}
      id="modal3"
      onClick={handleClick}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Кошелек</div>
          <div
            className="dialog__head-close"
            id="modal3Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className="dialog__body">
          <ul className="select-options">
            {selectOptionWallet.map(({ name, id }) => (
              <li
                style={{ cursor: "pointer" }}
                key={id}
                className={
                  selectedWallet === id
                    ? "select-option select-option_active"
                    : "select-option"
                }
                onClick={() => {
                  setSelectedWallet(id);
                  setMethodNameWallet(name);
                }}
              >
                {name}
              </li>
            ))}
          </ul>

          <div className="mt-50">
            <button
              className="btn btn-success btn_sm"
              id="select-accept"
              onClick={handleSubmit}
            >
              Принять
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWallet;
