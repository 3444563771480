import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import "dayjs/locale/ru";
import * as dayjs from "dayjs";
import ShopStatistics from "./ShopStatistics";
import ShopWidgets from "./ShopWidgets";
import ShopCurrensies from "./ShopCurrensies";
import ShopWithdrawals from "./ShopWithdrawals";
import ShopAppeals from "./ShopAppeals";

import { $authHost } from "../common/api";
import { BackIcon } from "./Icons";

const Shop = (props) => {
  const navigate = useNavigate();

  const tryBack = () => {
    // navigate(-1);
    navigate("/dashboard");
  };

  useEffect(() => {
    dayjs.locale("ru");
  }, []);

  const { shopId, tab } = props;

  const [selectedTab, setSelectedTab] = useState(tab);

  const tabs = [
    // Statistics (info)
    { id: "info", name: "Статистика" },
    // Appeals
    { id: "appeals", name: "Апелляции" },
    // Currencies
    { id: "currencies", name: "Настройки и валюты" },
    // Widgets
    { id: "widgets", name: "Виджеты" },
    // Withdrawals
    { id: "withdrawals", name: "Вывод средств" },
  ];

  configure({ axios: $authHost });

  const [{ data }] = useAxios("/merchant/shop/" + shopId + "/info");

  const handleSelectedTab = (shopId, tab) => {
    setSelectedTab(tab);
    navigate("/dashboard/shop/" + shopId + "/" + tab);
  };

  return (
    <div>
      <div className="main__head">
        <div className="text text_xl">{data?.shop?.name}</div>
        <div className="main__head-back" onClick={tryBack}>
          <BackIcon />
          <span>Назад</span>
        </div>
      </div>

      <div className="project-details">
        <div className="project-details__tabs">
          {tabs.map(({ name, id }) => (
            <div
              key={id}
              className={selectedTab === id ? "tab active" : "tab"}
              onClick={() => handleSelectedTab(shopId, id)}
            >
              {name}
            </div>
          ))}
        </div>
        {selectedTab === "info" && (
          <ShopStatistics shopId={shopId} shop={data?.shop} />
        )}
        {selectedTab === "currencies" && (
          <ShopCurrensies shopId={shopId} shop={data?.shop} />
        )}
        {selectedTab === "widgets" && <ShopWidgets shopId={shopId} />}
        {selectedTab === "withdrawals" && (
          <ShopWithdrawals shopId={shopId} shop={data?.shop} />
        )}
        {selectedTab === "appeals" && (
          <ShopAppeals shopId={shopId} shop={data?.shop} />
        )}
      </div>
    </div>
  );
};

export default Shop;
