import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ModalStatic from "./modal/ModalStatic";
import { formatFinance, truncateString } from "../common/utils";
import useDayjsFormat from "../hooks/useDayjsFormat";
import iconMoney from "../assets/img/moneyIcon.png";
import iconClick from "../assets/img/handClick.png";
import ErrorMark from "../assets/img/CrossMark.png";

const ShopPaymentsBlock = ({ info, addAppeal }) => {
  const [modalStatic, setModalStatic] = useState(false);

  const { getFullDate, getFullTime } = useDayjsFormat();

  let btnInnactive;
  let result;
  let icon;
  if (info.status === 2) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_success";
    result = "Оплачен";
    icon = iconMoney;
  } else if (info.status === 1) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_success";
    result = "Оплачен";
    icon = ErrorMark;
  } else if (info.status === 0) {
    btnInnactive = "btn-xs btn-innactive btn-warning";
    result = "Ожидает";
    icon = null;
  } else if (info.status === -1) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_draft";
    result = "Черновик";
  }

  return (
    <tr>
      <td data-label="ID">{info?.id}</td>
      <td data-label="Order">{info?.merchant_id}</td>
      <td data-label="E-mail" data-tooltip-id={"tooltip-email" + info?.id}>
        {truncateString(info?.email)}
        <ReactTooltip
          id={"tooltip-email" + info?.id}
          offset={-15}
          content={info?.email}
        />
      </td>
      <td data-label="Сумма">{formatFinance(info?.amount)} ₽</td>
      <td data-label="Зачислено">{formatFinance(info?.amount_to_shop)} ₽</td>
      <td data-label="Система">{info?.method_group}</td>
      <td data-label="Дата">
        {getFullTime(info?.updatedAt)}
        <br />
        {getFullDate(info?.updatedAt)}
      </td>
      <td
        data-label="Ответ"
        style={{
          minHeight: 75,
        }}
      >
        <div
          onClick={() => setModalStatic(true)}
          style={
            info.status !== -1 && info.status !== 0
              ? {
                  height: 42,
                  width: 42,
                  float: "right",
                  textAlign: "center",
                  cursor: "pointer",
                }
              : { display: "none" }
          }
        >
          <img
            src={icon}
            alt=""
            style={{
              width: 42,
              height: 42,
            }}
          />
          <img
            style={{
              position: "relative",
              width: 24,
              height: 24,
              top: -25,
            }}
            src={info.status !== -1 && info.status !== 0 ? iconClick : null}
            alt=""
          />
        </div>
        <ModalStatic
          active={modalStatic}
          setActive={setModalStatic}
          id={info.id}
        />
      </td>
      <td data-label="Статус">
        <span className={btnInnactive}>{result}</span>
      </td>
      <td data-label="Действия">
        {info.status === 0 && (
          <button
            className="btn btn-xs btn-success btn_minimal"
            onClick={() => {
              addAppeal(info?.id);
            }}
          >
            Апелляция
          </button>
        )}
      </td>
    </tr>
  );
};

export default ShopPaymentsBlock;
