import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { isNumeric } from "../common/utils";

const ShopWidgets = (props) => {
  const { shopId } = props;

  const [dataOrderId, setDataOrderId] = useState("");
  const [dataAmount, setDataAmount] = useState("");
  // eslint-disable-next-line
  const [copy, setCopy] = useState(false);
  const [widgetURL, setWidgetsURL] = useState("");

  let errors = {
    currency_amount: isNumeric(dataAmount)
      ? null
      : {
          message: "Допустимы только цифры",
        },
  };

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: `/merchant/order/create/from/merchant`,
      method: "POST",
    },
    axiosConfig
  );

  const handleChangeDataOrderId = (value) => {
    setWidgetsURL("");
    setDataOrderId(value);
  };

  const handleChangeDataAmount = (value) => {
    setWidgetsURL("");
    setDataAmount(value.toString().replace(/,/g, "."));
  };

  const handleClick = async () => {
    executePost({
      data: {
        amount: dataAmount,
        shop_id: shopId,
        merchant_order_id: "" + dataOrderId,
      },
    })
      .then((data) => {
        setWidgetsURL(data?.data?.url);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        setWidgetsURL("");
      });
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  return (
    <div
      className="currency-wrapper"
      style={{
        background: "rgba(29, 33, 41, 0.5)",
        borderRadius: 20,
        padding: "10px 30px 20px",
        marginTop: 35,
      }}
    >
      <div className="custom-input custom-input_block" style={{ marginTop: 0 }}>
        <label htmlFor="currency-order">Номер заказа</label>
        <input
          value={dataOrderId}
          type="text"
          id="currency-order"
          autoComplete="off"
          placeholder="Введите номер"
          onChange={(e) => handleChangeDataOrderId(e.target.value)}
        />
      </div>
      <div className="custom-input custom-input_block">
        <label htmlFor="currency-amount">Сумма заказа</label>
        <input
          value={dataAmount}
          type="text"
          id="currency-amount"
          autoComplete="off"
          placeholder="Введите сумму"
          onChange={(e) => handleChangeDataAmount(e.target.value)}
        />
        {getErrorBlock(errors?.currency_amount)}
      </div>
      <div className="custom-input custom-input_block">
        {
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontWeight: "bold" }}>URL</div>
              <input
                disabled={true}
                value={widgetURL}
                style={{ marginLeft: 20, fontWeight: "bold" }}
                placeholder="Для получения URL введите информацию выше"
                onChange={(e) => setWidgetsURL(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", marginBottom: 20 }}>
              {widgetURL ? (
                <div style={{ marginTop: 10 }}>
                  <CopyToClipboard
                    text={widgetURL}
                    onCopy={() => setCopy(true)}
                  >
                    <button
                      onClick={() =>
                        toast.success(`Ссылка скопирована ${widgetURL}`)
                      }
                      className="btn btn-success"
                      style={{
                        padding: "10px 20px",
                        marginTop: 10,
                        marginRight: 20,
                        background: "#4b1d2a",
                      }}
                    >
                      Скопировать
                    </button>
                  </CopyToClipboard>

                  <button
                    className="btn btn-success"
                    style={{
                      padding: "10px 20px",
                      marginTop: 10,
                      background: "#4b1d2a",
                    }}
                    onClick={() => window.open(widgetURL)}
                  >
                    Перейти в новой вкладке
                  </button>
                </div>
              ) : null}
            </div>
          </>
        }
      </div>
      <button
        className="btn btn-success"
        style={{ marginTop: 20 }}
        disabled={loading}
        onClick={handleClick}
      >
        Получить
      </button>
    </div>
  );
};

export default ShopWidgets;
